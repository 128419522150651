import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutTemplate = ({ data }) => {
	const { html, frontmatter } = data.markdownRemark;
	const profileImage = getImage(frontmatter.profile_image);

	return (
		<Layout title={frontmatter.title}>
			<AboutWrapper>
				<Insert>
					{/* <AboutImageWrapper image={profileImage} alt="" /> */}

					<AboutCopy dangerouslySetInnerHTML={{ __html: html }} />
				</Insert>
			</AboutWrapper>
		</Layout>
	);
};

export default AboutTemplate;

const AboutWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 100%;

	@media screen and (max-width: 1000px) {
		& {
			flex-direction: column;
		}

		& > * {
			margin-top: 2rem;
			width: 100%;
			text-align: center;
		}
	}
`;

const Insert = styled.section`
	body.light-mode & {
		backdrop-filter: blur(10px);
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 20px;
		background-color: rgba(200, 200, 200, 0.3);
		padding: 24px;
	}

	body.dark-mode & {
		backdrop-filter: blur(10px);
		border: 1px solid rgba(175, 175, 175, 0.5);
		border-radius: 20px;
		background-color: rgba(25, 25, 25, 0.6);
		padding: 24px;
	}
`;

const AboutImageWrapper = styled(GatsbyImage)`
	display: block;
	border-radius: 50%;
	height: 300px;
	width: 300px;
`;

const AboutCopy = styled.div`
	max-width: 60ch;

	& p {
		font-size: var(--size-400);
	}
`;

export const pageQuery = graphql`
	query ($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				profile_image {
					childImageSharp {
						gatsbyImageData(placeholder: BLURRED, formats: PNG, height: 400)
					}
				}
			}
		}
	}
`;
