import { Link } from "gatsby";
import styled from "styled-components";

const StyledLink = styled(Link)`
	padding: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	color: inherit;
	background-color: rgba(255, 255, 255, 0.4);
	text-decoration: none;
	border-radius: 0px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	text-transform: uppercase;
	border-radius: 4px;

	body.dark-mode & {
		backdrop-filter: blur(10px);
		border: 1px solid rgba(175, 175, 175, 0.5);
		border-radius: 20px;
		background-color: rgba(25, 25, 25, 0.6);
	}
	
	body.dark-mode &:hover {
		background-color: rgba(60, 60, 60, 0.5);
	}
`;

export default StyledLink;
