import React, { useMemo, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import PostList, { convertRemarkDataToPost } from "../components/post-list";
import styled from "styled-components";
import { Index } from "elasticlunr";

const Blog = ({ data }) => {
  const [query, setQuery] = useState("");
  const index = Index.load(data.siteSearchIndex.index);
  const posts = data.allMarkdownRemark.nodes;

  const searchResults = index
    .search(query, { expand: true })
    .map(({ ref }) => index.documentStore.getDoc(ref).id);

  const resultsToShow = query
    ? posts.filter((post) => searchResults.includes(post.id))
    : posts;

  return (
    <Layout title="Posts">
      <HeaderWrapper>
        <h1>Blog</h1>

        <Link
          css={`
            margin-top: var(--size-400);
            color: inherit;
            text-transform: uppercase;
          `}
          to="/tags">
          view all tags
        </Link>
      </HeaderWrapper>

			<SearchBarContainer>
				<SearchBar
					type="text"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
					placeholder="Search"
				/>
			</SearchBarContainer>

      {resultsToShow.length ?
				<PostList posts={resultsToShow} /> :
				<p>No results</p>}
    </Layout>
  );
};

export default Blog;

const SearchBarContainer = styled.div`
	display: flex;
	justify-content: center;
`

const SearchBar = styled.input`
	padding: 0.375rem;
	line-height: 1.5rem;
	width: 500px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--size-900);
  margin-bottom: var(--size-700);

  h1 {
    max-width: none;
  }
`;

export const homePageQuery = graphql`
  query {
    siteSearchIndex {
      index
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          description
          title
          tags
          social_image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
  }
`;
